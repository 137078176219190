<template>
    <div class="more-platform">
        <el-scrollbar class="more-platform-list-scrollbar">
            <div class="more-platform-wrapper">
                <p class="more-platform-title">短视频创作平台</p>
                <div class="more-platform-item"  v-for="item in addAccountData" @click="platformClick(item)">
                    <img :src="item.imgUrl" alt="">
                    <span class="platform-title">{{item.platform_type}}</span>
                </div>
            </div>
<!--            <div class="more-platform-wrapper">-->
<!--                <p class="more-platform-title">图文创作平台</p>-->
<!--                <div class="more-platform-item"  v-for="item in culturalData" @click="platformClick(item)">-->
<!--                    <img :src="item.imgUrl" alt="">-->
<!--                    <span class="platform-title">{{item.platform_type}}</span>-->
<!--                </div>-->
<!--            </div>-->
        </el-scrollbar>
    </div>
</template>

<script>
    export default {
        platform_type: "AddAccountManageModule",
        data(){
            return{
                addAccountData: [
                    {
                        imgUrl: require("../../assets/img/image/douyin.png"),
                        platform_type:'抖音号',
                        platform:1,
                        openState:true,
                    },
                    // {
                    //     imgUrl: require("../../assets/img/image/iqiyi.png"),
                    //     platform_type:'爱奇艺号',
                    //     platform:2,
                    //     openState:false,
                    // },
                    // {
                    //     imgUrl: require("../../assets/img/image/youku.png"),
                    //     platform_type:'优酷',
                    //     platform:3,
                    //     openState:false,
                    // },
                    // {
                    //     imgUrl: require("../../assets/img/image/wy.png"),
                    //     platform_type:'网易号',
                    //     platform:4,
                    //     openState:false,
                    // },
                    // {
                    //     imgUrl: require("../../assets/img/image/mgtv.png"),
                    //     platform_type:'芒果吧',
                    //     platform:5,
                    //     openState:false,
                    // },
                    // {
                    //     imgUrl: require("../../assets/img/image/xmly.png"),
                    //     platform_type:'喜马拉雅',
                    //     platform:6,
                    //     openState:false,
                    // },
                    // {
                    //     imgUrl: require("../../assets/img/image/df.png"),
                    //     platform_type:'大风号',
                    //     platform:7,
                    //     openState:false,
                    // },
                    // {
                    //     imgUrl: require("../../assets/img/image/miaopai.png"),
                    //     platform_type:'秒拍',
                    //     platform:8,
                    //     openState:false,
                    // },
                    // {
                    //     imgUrl: require("../../assets/img/image/bili.png"),
                    //     platform_type:'哔哩哔哩',
                    //     platform:9,
                    //     openState:false,
                    // },
                    // {
                    //     imgUrl: require("../../assets/img/image/shsp.png"),
                    //     platform_type:'搜狐视频',
                    //     platform:10,
                    //     openState:false,
                    // },
                    // {
                    //     imgUrl: require("../../assets/img/image/qmxsp.png"),
                    //     platform_type:'全民小视频',
                    //     platform:11,
                    //     openState:false,
                    // },
                    // {
                    //     imgUrl: require("../../assets/img/image/bmh.png"),
                    //     platform_type:'爆米花',
                    //     platform:12,
                    //     openState:false,
                    // },
                    // {
                    //     imgUrl: require("../../assets/img/image/ks.png"),
                    //     platform_type:'快手号',
                    //     platform:13,
                    //     openState:false,
                    // },
                    // {
                    //     imgUrl: require("../../assets/img/image/tx.png"),
                    //     platform_type:'腾讯视频',
                    //     platform:14,
                    //     openState:false,
                    // },
                    // {
                    //     imgUrl: require("../../assets/img/image/hotdy.png"),
                    //     platform_type:'抖音火山版',
                    //     platform:15,
                    //     openState:false,
                    // },
                    // {
                    //     imgUrl: require("../../assets/img/image/my.png"),
                    //     platform_type:'美柚',
                    //     platform:16,
                    //     openState:false,
                    // },
                    // {
                    //     imgUrl: require("../../assets/img/image/ws.png"),
                    //     platform_type:'微视',
                    //     platform:17,
                    //     openState:false,
                    // },
                    // {
                    //     imgUrl: require("../../assets/img/image/xhs.png"),
                    //     platform_type:'小红书',
                    //     platform:18,
                    //     openState:false,
                    // },
                    // {
                    //     imgUrl: require("../../assets/img/image/wxsph.png"),
                    //     platform_type:'微信视频号',
                    //     platform:19,
                    //     openState:false,
                    // },
                    // {
                    //     imgUrl: require("../../assets/img/image/fw.png"),
                    //     platform_type:'蜂网',
                    //     platform:20,
                    //     openState:false,
                    // },
                ],
                culturalData:[
                    {
                        imgUrl: require("../../assets/img/image/baijiahao.png"),
                        platform_type:'百家号',
                        platform:1,
                        openState:true,
                    },

                    {
                        imgUrl: require("../../assets/img/image/qq.png"),
                        platform_type:'企鹅号',
                        platform:2,
                        openState:false,

                    },
                    {
                        imgUrl: require("../../assets/img/image/yidian.png"),
                        platform_type:'一点号',
                        platform:3,
                        openState:false,
                    },

                    {
                        imgUrl: require("../../assets/img/image/weibo.png"),
                        platform_type:'微博头条号',
                        platform:4,
                        openState:false,
                    },

                    {
                        imgUrl: require("../../assets/img/image/ppx.png"),
                        platform_type:'皮皮虾',
                        platform:4,
                        openState:false,
                    },

                    {
                        imgUrl: require("../../assets/img/image/js.png"),
                        platform_type:'简书号',
                        platform:5,
                        openState:false,
                    },

                    {
                        imgUrl: require("../../assets/img/image/weixin.png"),
                        platform_type:'微信公众号',
                        platform:6,
                        openState:false,
                    },
                    {
                        imgUrl: require("../../assets/img/image/xinlang.png"),
                        platform_type:'新浪微博',
                        platform:7,
                        openState:false,
                    },
                    {
                        imgUrl: require("../../assets/img/image/sg.png"),
                        platform_type:'搜狗号',
                        platform:8,
                        openState:false,
                    },

                    {
                        imgUrl: require("../../assets/img/image/qt.png"),
                        platform_type:'趣头条',
                        platform:9,
                        openState:false,
                    },
                    {
                        imgUrl: require("../../assets/img/image/dayu.png"),
                        platform_type:'大鱼号',
                        platform:10,
                        openState:false,
                    },
                    {
                        imgUrl: require("../../assets/img/image/sh.png"),
                        platform_type:'搜狐号',
                        platform:11,
                        openState:false,
                    },

                    {
                        imgUrl: require("../../assets/img/image/kch.png"),
                        platform_type:'快传号',
                        platform:12,
                        openState:false,
                    },
                ]
            }
        },
        methods:{
            platformClick(item){
                if (item.openState){
                    this.$emit('platformSelect',item)
                }else {
                    this.$message.info('该软件还未开通权限，敬请期待！');
                }
            }
        }
    }
</script>

<style scoped lang="scss">
        .more-platform{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 300px;
            .more-platform-list-scrollbar{
                height: 100%;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                ::v-deep .el-scrollbar__view{
                    display: flex;
                    flex-wrap: wrap;
                }
            }
            .more-platform-wrapper{
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                .more-platform-item{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 10%;
                    margin-bottom: 22px;
                    img{
                        width: 50px;
                        min-height: 50px;
                        margin: 10px 0;
                        box-sizing: border-box;
                        cursor: pointer;
                    }

                    .platform-title{
                        color: #666;
                        font-size: 14px;
                    }
                }
                .more-platform-title{
                    display: inline-block;
                    width: 100%;
                }
            }
        }
</style>
